import { graphql, PageProps } from "gatsby"
import Home from "../components/Home"
import Seo from "../components/SEO"
import YAMLData from "../data/homepage.yml"

const IndexPage: React.FC<{ data: any }> = ({ data }) => {
  const copy = YAMLData
  const assets = data.allAsset.nodes
  return (
    <div>
      <Seo title="Home" />
      <Home data={copy} assets={assets} />
    </div>
  )
}

export default IndexPage

export const query = graphql`
  {
    allAsset {
      nodes {
        assetID
        collection
        eventConfigID
        fileExtension
        fileName
        id
        originalFileName
      }
    }
  }
`
